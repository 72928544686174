import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/ant-design-vue.js'
import VueNativeNotification from 'vue-native-notification'

// import './static/js/mock'
Vue.config.productionTip = false
Vue.use(VueNativeNotification, {
  requestOnNotify: true
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
