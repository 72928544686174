import axios from 'axios';
import {getCookie} from '@/static/js/cookie';
import router from '@/router'
import Vue from 'vue'
// 创建一个axios实例


const service = axios.create({

  // headers: {
  //   'content-type': 'application/json;charset=UTF-8',
  //   'Authorization': getCookie("token")?JSON.parse(getCookie("token")).token:''
  // },

  baseURL: process.env.VUE_APP_ApiUrl||'',
  // withCredentials: true,
  timeout: 15000
})


  //如果是本地的，多加一个参数，
  // let session_id=''
  // if (process.env.NODE_ENV === 'development') {
  //     session_id=getCookie('session_id')
  //     config.params={...config.params,session_id:session_id}
  // }
// 添加请求拦截器
service.interceptors.request.use(config => {
  // 在发送请求之前做某事，比如说 设置token
  config.headers['Authorization'] = getCookie("token")?JSON.parse(getCookie("token")).token:'';
  //config.headers['X-Token'] = 'xxxxxxxxxxxxxxxxxxx';
  return config;

}, error => {
  // 请求错误时做些事
});

// 添加响应拦截器
service.interceptors.response.use(response => {
  const res = response.data;
  
// 如果返回的状态不是200 就主动报错
  if(res.status === 0){
    if(res.errcode==10002){
      router.push("/login")
    }
    Vue.prototype.$message.error(res.errmsg);
    // return Promise.reject(res.errmsg || 'error')
    return res
  }
  return res;
}, error => {
  Vue.prototype.$message.error('请求超时或者服务器出错');
  return '请求超时'; // 返回接口返回的错误信息
});

//给接口加上网站地址

export function get(url, data = {}) {
  return service({
    method: 'get',
    url: url,
    params:data,
  })
}
export function getBlob(url, data = {}) {
  return service({
    method: 'get',
    url: url,
    params:data,
    responseType: 'blob'
  })
}
export function post(url, data = {}) {
  return service({
    method: 'post',
    url:url,
    data:data,
  })
}
